<template>
  <WhatWeDo
    :background-image="this.backgroundImage"
    :background-image-details="'Image Description'"
  >
    <h1>Keypad Interactive Voting System</h1>
      <div class="scrollable-content">
    <p>
      This easy to use business tool enables a company to promote scientific
      data and key messaging that can be be passed onto delegates at exhibitions
      &amp; events, business training, work and leisure meetings. This can be
      utilised in large or small teams of up to 12 to build unity or
      individually played.
    </p>
    <p>
      This can also be operated offline without an internet connection making it
      more reliable in busy congress environments.
    </p>
    <p>
      This system enables live feedback with its real time assessment, it can
      also be anonymous in its responses and offer a full report of statistics.
      Data can also be exported into an Excel spreadsheet where it can be
      analysed to produce charts to visualise data trends in greater detail.
    </p>
    <p>This activity can determine the following:</p>
    <ul>
      <li>Number of Delegates</li>
      <li>Nationality of Delegates</li>
      <li>Delegate’s Occupation</li>
      <li>Determine how many and which answers are answered correctly</li>
      <li>Determine how many and which answers are answered incorrectly</li>
      <li>
        Any gaps in their knowledge or understanding of products and procedures
        based upon the questions answered.
      </li>
      <li>The time the delegates took to answer</li>
    </ul>
    <h3>The Process</h3>
    <ol>
      <li>
        Delegates are engaged and welcomed onto the booth by our Moderator
      </li>
      <li>
        Delegates take a seat and are handed a numbered keypad with number or
        letters displayed.
      </li>
      <li>
        Moderator explains how the activity will work. Moderator will present
        from the main screen a series of questions after which answer options
        will appear. and delegates can choose against the clock the correct
        option by pressing the corresponding option number on their keypad.
        Speed response is measured as well as the correct option chosen.
      </li>
      <li>The activity starts with delegates viewing the main screen.</li>
      <li>
        Moderator presents a question on the main screen before or after any
        desired graphs, images and videos are displayed.
      </li>
      <li>The answer options will appear on the main screen</li>
      <li>Countdown clock begins.</li>
      <li>
        Moderator will then reveal highlighting the correct answer after the
        countdown.
      </li>
      <li>
        A voting bar shows the percentage of right and wrong answers under each
        option answer so at a glance it is possible to know how the overall
        group responded.
      </li>
      <li>
        Moderator can present on the main screen an additional explanation slide
        after each question reaffirming the correct answer.
      </li>
      <li>
        A leader board can be shown part way through the activity and a final
        results board at the end
      </li>
    </ol>
      </div>
  </WhatWeDo>
</template>
<script>
import WhatWeDo from '@/components/WhatWeDo.vue'
export default {
  components: { WhatWeDo },
  data() {
    return {
      backgroundImage: require('@/assets/images/what-we-do/keypad-voting-system.jpg'),
    }
  },
}
</script>     